import PageSitterApplication from '../components/PageSitterApplication'
import { graphql } from 'gatsby'

export const query = graphql`
  query PageSitterApplication {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default PageSitterApplication
